import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import RingpoemsIntro from './ringpoemsIntro'

// markup
const Posies_a = () => {
  return (
   <Layout >
      <Head title='Ring Poems (Posies) in English A-F'/>

   
    <RingpoemsIntro />
    <h2>A - F</h2>
    <br/>
A CONSTANT HEART <br/>
WITHIN A WOMAN'S BREST <br/>
IS OPHIRE GOLD <br/>
WITHIN AN IVORY CHEST &nbsp;<br/>
<br/>
A FRIEND INDEED IN TYME OF NEED &nbsp;<br/>
<br/>
A FRIEND TO ONE AS LIKE TO NONE <br/>
<br/>
A FRIEND TO THEE I'LL EVER BE <br/>
<br/>
A HAPPY BREST WHERE LOVE DOTH REST <br/>
<br/>
A HEART CONTENT CANNOT REPENT <br/>
<br/>
A HEART UNITED TRUE CONTENTED <br/>
<br/>
A KISS FOR THIS &nbsp;<br/>
<br/>
A LOVEING WIFE A HAPPY LIFE<br/>
<br/>
A LOVING WIFE PROLONGETH LIFE &nbsp;<br/>
<br/>
A MAIDEN VERTUOUS CHASTE AND FAIR <br/>
IS A JEWEL PAST COMPARE <br/>
AND SUCH ARE YOU IN WHOM I FIND <br/>
VERTUE IS WITH BEAUTY JOYN'D <br/>
<br/>
A TOKEN SENT WITH TRUE INTENT <br/>
<br/>
A VIRTUOUS WIFE A HAPPY LIFE <br/>
<br/>
A VIRTUOUS WIFE DOTH BANISH STRIFE &nbsp;<br/>
<br/>
A WOMAN KIND ALL JOY OF MIND &nbsp;<br/>
<br/>
ACCEPT OF THIS MY HEART WITHALL <br/>
MY LOVE IS GREAT THOUGH THIS BE SMALL &nbsp;<br/>
<br/>
AFTER CONSENT EUER CONTENT &nbsp;<br/>
<br/>
ALL I REFUSE BUT THEE I CHUSE <br/>
<br/>
AL MYNE SHAL BE THYNE &nbsp;<br/>
<br/>
ALL PERFECT LOVE <br/>
IS FROM ABOVE <br/>
THE SIGHT OF THIS <br/>
DESERVES A KISSE &nbsp;<br/>
<br/>
ALL THINE IS MINE &nbsp;<br/>
<br/>
AND AS THIS ROUND <br/>
IS NOWHERE FOUND <br/>
TO FLAW OR ELSE TO SEVER <br/>
SO MAY OUR LOVE <br/>
AS ENDLESS PROVE <br/>
AS PURE AS GOLD FOR EVER &nbsp;<br/>
<br/>
AS GOD HATH KNIT OUR <br/>
HEARTS IN ONE <br/>
LET NOTHING PART BUT DEATH <br/>
ALONE &nbsp;<br/>
<br/>
AS GOD HATH MADE MY <br/>
CHOYCE IN THEE <br/>
SO MOVE THY HEART TO <br/>
COMFORT ME &nbsp;<br/>
<br/>
AS GOD SAW FITT OUR KNOTT IS KNITT &nbsp;<br/>
<br/>
AS GOLD IS PURE LET LOVE INDURE &nbsp;<br/>
<br/>
AS GOLD IS PURE SO LOVE IS SHURE &nbsp;<br/>
<br/>
AS HEAVEN OUR LOVE DECREED <br/>
IN HEAVENLY LOVE LET US PROCEED &nbsp;<br/>
<br/>
AS I EXPECT SO LET ME FIND <br/>
A FAITHFULL HEART A CONSTANT MIND &nbsp;<br/>
<br/>
AS I IN THEE HAVE MADE MY CHOYCE <br/>
SO IN THE LORD LET US REJOYCE &nbsp;<br/>
<br/>
AS LONG AS LIFE Yr LOVING WIFE &nbsp;<br/>
<br/>
AS LOVE HATH JOYNED OUR <br/>
HARTS TOGETHER <br/>
SO NONE BUT DEATH OUR <br/>
HARTS SHALL SEVER &nbsp;<br/>
<br/>
AS TRUE TO LOVE AS TURTLE DOVE &nbsp;<br/>
<br/>
AS TRUE TO THEE AS THOU TO ME &nbsp;<br/>
<br/>
AS TRUST BEE JUST <br/>
<br/>
AS YOU FINDE ME MINDE ME &nbsp;<br/>
<br/>
AS YOU NOW FIND SO JUDGE ME KIND &nbsp;<br/>
<br/>
BE CONSTANT YOU FOR I AM TREW<br/>
<br/>
BE CONSTANT U AS I AM TRUE <br/>
<br/>
BE IT MY FORTUNE OR MY FAULT <br/>
LOVE MAKES ME VENTURE THIS ASSAULT &nbsp;<br/>
<br/>
BE KINDE IN HARTE &nbsp;<br/>
<br/>
BE KIND TO ME I WILL TO THE <br/>
<br/>
BE THOU MINE <br/>
AS I AM THINE <br/>
<br/>
BE TRUE AS FAIR THEN PAST COMPARE &nbsp;<br/>
<br/>
BE TRUE TO ME AS I TO THEE &nbsp;<br/>
<br/>
BE TRUE TO ME Yt GIVES IT THEE &nbsp;<br/>
<br/>
BE TRULY WISE LEST DEATH SURPRISE &nbsp;<br/>
<br/>
BEHOLD THE ENDE <br/>
RATHER DEATH THEN FALS FAITH &nbsp;<br/>
<br/>
BEYOND THIS LIFE LOVE ME DEARE WIFE <br/>
<br/>
BODY AND MINDE IN THEE I FINDE <br/>
<br/>
BOTH MUST BE ONE OR ONE BE NONE &nbsp;<br/>
<br/>
BOTH OR NEITHER CHUSE YOU WHETHER &nbsp;<br/>
<br/>
BRACELETS I'LE GIVE<br/>
EMBRACE LETS EVER <br/>
LET PARTLETS GO <br/>
FOR PART LETS NEVER &nbsp;<br/>
<br/>
BREAKE NOT THY VOW TO PLEASE THE EYE <br/>
BUT KEEPE THY LOVE SO LIVE AND DYE &nbsp;<br/>
<br/>
BY CUPID'S BOW MY WEAL OR WOE &nbsp;<br/>
<br/>
BY GOD'S DIRECTION WE JOYN AFFECTION <br/>
<br/>
BY THIS I PROVE HOW MUCH I LOVE &nbsp;<br/>
<br/>
BY THIS RING OF GOLD <br/>
TAKE ME TO HAVE AND HOLD &nbsp;<br/>
<br/>
CAREFUL I'LE BE TO COMFORT THEE &nbsp;<br/>
<br/>
COME MY LOVE IF LOVE YOU GRANT <br/>
WHAT IS IT THAT LOVE CAN WANT <br/>
IN THEE I HAVE SUFFICIENT STORE <br/>
GRANT ME THY LOVE I WISH NO MORE &nbsp;<br/>
<br/>
CONSTANT AND TRUE I'LL BE TO YOU &nbsp;<br/>
<br/>
CONSTANT TRUE LOVE COMES FROM ABOVE &nbsp;<br/>
<br/>
CONSTANT YOU FOR I AM TRUE &nbsp;<br/>
<br/>
CONTENT INDEED DOTH GOLD EXCEED &nbsp;<br/>
<br/>
CONTENTED WITH MY CHOICE &nbsp;<br/>
<br/>
DEAR LOVE OF MINE MY HEART IS THINE &nbsp;<br/>
<br/>
DEAREST IF I HAVE OFFENDED <br/>
ENJOYN ME THEN SOME PENNANCE HARD <br/>
THAT MY FAULT MAY BE AMENDED <br/>
ERE YOUR FAVOUR BE DEBARD <br/>
FOR IF I MUST PENNANCE DO <br/>
I'LE GO UNTO NO SAINT BUT YOU &nbsp;<br/>
<br/>
DEATH NEVER PARTS SUCH LOVING HEARTS <br/>
<br/>
DEATH ONLY PARTES TWO LOVING HEARTES &nbsp;<br/>
<br/>
DESIRE AND DESERVE &nbsp;<br/>
<br/>
DESIRE HATH NO REST &nbsp;<br/>
<br/>
DESIRE HATH SET MY HEART ON FIRE &nbsp;<br/>
<br/>
DESIRE. LIKE FIRE DOTH STILL ASPIRE &nbsp;<br/>
<br/>
DESIRE NOT MEE Yt JOYES IN THEE &nbsp;<br/>
<br/>
DIAMOND <br/>
E MERALD <br/>
AMETHYST <br/>
RUBY <br/>
EMERALD <br/>
SAPPHIRE <br/>
TOPAZ &nbsp;<br/>
<br/>
DIRECT OUR WAYES LORD ALL OUR DAYES &nbsp;<br/>
<br/>
DIVINELY KNIT BY GOD ARE WE <br/>
LATE ONE NOW TWO THE PLEDGE YOU SEE &nbsp;<br/>
<br/>
ENDLESS AS THIS SHALL BE OUR BLISS &nbsp;<br/>
<br/>
ENDLESS BEE MY LOVE TO THEE &nbsp;<br/>
<br/>
ETERNALLY MY LOVE SHAL BE &nbsp;<br/>
<br/>
EVER TRUE MY DEAR TO YOU <br/>
<br/>
EVERY LETTER HERE DOTH SHEW <br/>
THAT MY HEART IS LINK'T TO YOU <br/>
AND BY THIS TOKEN IS EXPREST <br/>
THAT YOU ARE SHE WHOM I LOVE BEST &nbsp;<br/>
<br/>
EYE DOTH FIND HEART DOTH CHOOSE <br/>
FAITH DOTH BIND DEATH DOTH LOSE &nbsp;<br/>
<br/>
FAIR AS VENUS AS DIANA <br/>
CHAST AND PURE IS MY SUSANA &nbsp;<br/>
<br/>
FEARE GOD AND LYE ABED TILL NOONE &nbsp;<br/>
<br/>
FEAR GOD HONOUR THE PRINCE <br/>
LYE STILL JOAN AND DON'T WINCE &nbsp;<br/>
<br/>
FLESH OF MY FLESH BONE OF MY BONE <br/>
FROM ONE MADE TWO IS TWO MADE ONE &nbsp;<br/>
<br/>
FOR A KISS TAKE THIS &nbsp;<br/>
<br/>
FOR ONE AND LOVE SOME SAY ARE BLIND <br/>
I SAY THEY SEE IF THOU PROVE KIND &nbsp;<br/>
<br/>
<hr/>
   </Layout>
  )
}

export default Posies_a
